import React, { useState, useContext } from 'react';
import "./NavPaneStyle.css"
import { NavLink as Link } from 'react-router-dom';
import { QueryContext } from "../../../context/QueryContext";
import { Button } from "antd";
import { MenuOutlined, UpOutlined, DownOutlined } from '@ant-design/icons';


export const Navbar = () => {
  const { queryState, queryDispatch } = useContext(QueryContext);
  const [activeButton, setactiveButton] = useState(null);

  // console.log("Navigation Pane :: ", queryState.isLoad, "  ", queryState.isReportData);
  return (
      <nav className="nav-bar">
        <div className="nav-menu">
          <Link exact={true} to='/query' onClick={()=>setactiveButton(false)}>
            Query
          </Link>
          <Link to='/results' onClick={()=>setactiveButton(false)}>
            Query Result
          </Link>
          {/* <Link to='/reports' onClick={()=>setactiveButton(true)}>
            Reports
          </Link> */}
          {/* {queryState.isReportData ? (
            <Link to='/summary' onClick={()=>setactiveButton(true)}>
              Summary
            </Link>
          ): ([])} */}
          <Link to='/reports' onClick={()=>setactiveButton(true)}>
              Reports
          </Link>
          <Link to='/kyc' onClick={()=>setactiveButton(true)}>
              Kyc
          </Link>
          {/* <Link to='/minio' onClick={()=>setactiveButton(true)}>
            Minio
          </Link> */}
          {process.env.REACT_APP_ENVIRONMENT ==="dev"?(
            <Link to='/search' onClick={()=>setactiveButton(true)}>
              Address Search
            </Link>):([])}
          {/* {activeButton?(
            <React.Fragment>
              <Button
                className="nav-bar-button"
                type="primary"
                icon={<MenuOutlined/>}
                onClick={()=>queryDispatch({ type: "SHOW_REPORT_DROPDOWN", value:queryState.reportDropdown?false:true })}
              />
            </React.Fragment>
          ):([])} */}
        </div>
      </nav>
  );
};

export default Navbar;