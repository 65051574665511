/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-throw-literal */
import React, { useContext, useEffect, useRef } from 'react'
import { Row, Col, DatePicker, Space, Button, Table, Spin, Checkbox, Input } from "antd";
import { Alert, message } from 'antd';
import { DownloadOutlined, SearchOutlined, LoadingOutlined } from '@ant-design/icons';
// eslint-disable-next-line
import "antd/dist/antd.min.css";
import "bootstrap/dist/css/bootstrap.css";
import { QueryContext } from "../../../context/QueryContext";
import XLSX from "xlsx"
import './Kyc.css'
import moment from 'moment';
import dal from "../../../dal/api-call"
import DropDownMenu from '../DropDownMenu/DropDownMenu';

const dateFormat = 'YYYY-MM-DD HH:mm';

function Reports() {
  const { queryState, queryDispatch } = useContext(QueryContext);
  const searchInput = useRef(null);

  useEffect(async() => {
    if(queryState.issubmit&&queryState.offsetvalue!==undefined){
      await getReport()
    }
  }, [queryState.offsetvalue,queryState.issubmit])

  const crushObj = (obj = {}) => Object.keys(obj || {}).reduce((acc, cur) => {
    if (cur === 'headers' || cur === 'suffix' || (cur==='apiResponse' && obj['apiResponse']==='')){}
    else if (cur === "body" && typeof obj[cur] === 'string') {
      acc = { ...acc, ...crushObj(JSON.parse(obj[cur]))}
    }
    else if (typeof obj[cur] === 'object') {
      acc = { ...acc, ...crushObj(obj[cur])}
    } else { acc[cur] = obj[cur] }
    return acc
  }, {})

  const getReport = async () => {
    try {
      const response = await dal.request(`${process.env.REACT_APP_API_BASE_PATH}v1/compliance/report?fromDate=${queryState.fromDate}&toDate=${queryState.toDate}&query=${queryState.reportquery}&transactionDate=${queryState.reporttransactionDate}&transactionId=${queryState.reporttransactionId}&filterCondition=${queryState.filterparam}`)
      
      if(response){
        if(response.status === 200){

          typeof(response.data)==="string"?(response.data = JSON.parse(response.data)):(response.data= response.data)
          if(!response.data.DBRecords){
            throw {
              message:"Data not fetch"
            }
          }
          await queryDispatch({ type: "FETCH_REPORT_DATA", data:response.data.DBRecords, isdata:true, spin:false, entiredetailflag:queryState.entireDetailsFlag, offset:response.data.offset});
          let flatdata = await response.data.DBRecords.map(crushObj)
          flatdata = flatdata.filter(data=>data.source?true:false)
          await queryDispatch({ type: "FLATTEN_REPORT_DATA", value:flatdata });

          if(!response.data.offset){
            queryDispatch({ type: "IS_LOADING", load:false })
            queryDispatch({ type: "IS_SUBMITTING", issubmit:false })
            queryDispatch({ type: "REPORT_DATA_LENGTH" })

            await queryDispatch({ type: "SHOW_REPORT_DROPDOWN", value:false })
          }else{
            queryDispatch({ type: "IS_LOADING", load:true })

            await queryDispatch({ type: "SHOW_REPORT_DROPDOWN", value:false })
          }
          // console.log("total records :: ",queryState.data," ",flatdata.length," ",queryState.offsetvalue)
        }else{
          queryDispatch({ type: "IS_SPIN", spin:false })
          queryDispatch({ type: "REPORT_ERROR", value: response.errorMessage, iserror:true })
          queryDispatch({ type: "IS_LOADING", load:false })
          queryDispatch({ type: "IS_SUBMITTING", issubmit:false })
        }
      }else{
        queryDispatch({ type: "IS_SPIN", spin:false })
        queryDispatch({ type: "REPORT_ERROR", value: "Error void response !!", iserror:true })
        queryDispatch({ type: "IS_LOADING", load:false })
        queryDispatch({ type: "IS_SUBMITTING", issubmit:false })
      }
    } catch(error) {
      queryDispatch({ type: "IS_SPIN", spin:false })
      queryDispatch({ type: "IS_LOADING", load:false })
      queryDispatch({ type: "REPORT_ERROR", value: error.message, iserror:true })
      queryDispatch({ type: "IS_SUBMITTING", issubmit:false })
    };
  }

  function toDate(e) {
    if(!e){
      queryDispatch({ type: "UPDATE_TO_DATE", value: e })
    }
    if(e!==null){
      queryDispatch({ type: "UPDATE_TO_DATE", value: e.toISOString() })
    }
  }

  function fromDate(e) {
    if(!e){
      queryDispatch({ type: "UPDATE_FROM_DATE", value: e })
    }
    if(e!==null){
      queryDispatch({ type: "UPDATE_FROM_DATE", value: e.toISOString() })
    }
  }

  function submitReport(e) {
    e.preventDefault();
    if(queryState.toDate!==undefined && queryState.toDate!==null && queryState.fromDate!==undefined && queryState.fromDate!==null){
      queryDispatch({ type: "CLEAR_REPORT_DATA"})
      queryDispatch({ type: "IS_SPIN", spin:true })
      queryDispatch({ type: "IS_SUBMITTING", issubmit:true })
    }
    else{
      message.error("Please Select Date")
    }
  }

  function entireReportDetails(e){
    queryDispatch({ type: "UPDATE_ENTIRE_REPORT_DETAIL_CHECKED", value : e.target.checked})
  }

  function exportReportData(){
    // console.log("Export start !!!!")
    const fileName = "Report-"+moment(queryState.fromDate,"YYYY-MM-DD").format("DDMMYYYY")+"-"+moment(queryState.toDate,"YYYY-MM-DD").format("DDMMYYYY")+".xlsx"
    if(queryState.isReportData){
      const exportdata = (queryState.isFilteredReportData?(queryState.filteredReportData):(queryState.flattenedData)).map((record)=>{
        let keys = {}
        queryState.exportColumnslist.map((key)=>{
          if(key==='ReferenceNumber'){
            keys[(key[0].toUpperCase() + key.slice(1)).replace(/[A-Z]/g, ' $&').trim()] = record[key]===undefined||record[key]===''?("NOREF0000000"):(record[key])
          }
          else if(key==='RuleTriggered'){
            keys[(key[0].toUpperCase() + key.slice(1)).replace(/[A-Z]/g, ' $&').trim()] = record[key]===undefined||record[key]===''?("No Rule"):(record[key])
          }
          else if(key==="transactionDate"){
            keys[(key[0].toUpperCase() + key.slice(1)).replace(/[A-Z]/g, ' $&').trim()] = moment(record[key], "YYYY-MM-DDTHH:mm:ssZ").utcOffset(0).format("YYYY-MM-DD HH:mm:ss")
          }
          else{
            keys[(key[0].toUpperCase() + key.slice(1)).replace(/[A-Z]/g, ' $&').trim()] = record[key]
        }})
        return keys
      })
      // console.log("exportData :: ", exportdata)
      var longest = queryState.exportColumnslist.map((record)=>{
        var recrd=(record[0].toUpperCase() + record.slice(1)).replace(/[A-Z]/g, ' $&').trim()
        var long
        exportdata.map((cur)=>{if(cur[recrd]===undefined){} else if(long===undefined || long[recrd].length<cur[recrd].length){long = cur}else{} });
        const width=(long===undefined || long[recrd]===undefined)?(recrd.length):(
          (long[recrd].length>=recrd.length)?(long[recrd].length):(recrd.length))
        return {"width":width}
      })

      let headers = queryState.exportColumnslist.map((record)=>{
        return (record[0].toUpperCase() + record.slice(1)).replace(/[A-Z]/g, ' $&').trim()
      })

      var wb = XLSX.utils.book_new();
      var ws = XLSX.utils.json_to_sheet(exportdata, {header: headers});
      ws['!cols'] = longest
      XLSX.utils.book_append_sheet(wb, ws, 'REPORT')
      XLSX.writeFile(wb, fileName)
    }
  }

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };

  const handleReset = (clearFilters) => {
    clearFilters();
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) => text,
  });

  const onChange = (pagination, filters, sorter, extra) => {
    if(extra && extra.currentDataSource){
      queryDispatch({ type: "UPDATE_FILTERED_REPORT_DATA", value: extra.currentDataSource})
    }
  };

  const crushColumnObj = (obj = {}, ans = []) => Object.keys(obj || {}).reduce((acc, cur) => {
    let keys={}
    if(typeof(obj[cur])==="object"){
      if(Array.isArray(obj[cur])){
        if(cur==="ShowEntireReport"){
          if(queryState.entireDetails)(
            ans = ans.concat(crushColumnObj(obj[cur]))
          )
        }
        else{
          keys["title"] = (cur[0].toUpperCase() + cur.slice(1)).replace(/[A-Z]/g, ' $&').trim();
          keys["children"] = crushColumnObj(obj[cur])
          ans = ans.concat([keys])
        }
        
      }
      else{
        ans = ans.concat(crushColumnObj(obj[cur]))
      }
    }
    else{
      keys["title"] = (obj[cur][0].toUpperCase() + obj[cur].slice(1)).replace(/[A-Z]/g, ' $&').trim();
      keys["dataIndex"] = obj[cur]
      keys["key"] = obj[cur]
      if(["Decision","transactionDate","RuleTriggered","orderId","firstName","lastName","addressline1","DecisionDescription","productType"].includes(obj[cur])){
        keys = {...keys, ...getColumnSearchProps(obj[cur])}
      }
      if(obj[cur]==="orderReferenceNumber"){
        keys["title"] = "Order Reference No"
        keys['width'] = '160px'
        keys['sorter'] = (record1,record2)=> record1.ReferenceNumber > record2.ReferenceNumber
        keys['sortDirections'] = ['descend']
        keys = {...keys, ...getColumnSearchProps(obj[cur])}
      }
      else if(obj[cur]==='DecisionDescription'){
        keys['width'] = '730px'
      }
      else if(obj[cur]==='mid'){
        keys['width'] = '110px'
      }
      else if(obj[cur]==='ReferenceNumber'){
        keys["title"] = "Reference No"
        keys['width'] = '110px'
        keys = {...keys, ...getColumnSearchProps(obj[cur])}
      }
      else if(obj[cur]==='source'){
        keys['width'] = '110px'
        keys = {...keys, ...getColumnSearchProps(obj[cur])}
      }
      else{
        keys["width"] = obj[cur].length*9+18
      }
      ans = ans.concat([keys])
    }
    return ans
  }, {}
  );

  const columns = ()=>{
    if(queryState.isReportData){
      const column = crushColumnObj(queryState.tableColumnKeys)
      // console.log("Column :: ",column)
      return column
    }
    else{return[]}
  };

  const dataSource = ()=>{
    if(queryState.isReportData && queryState.flattenedData!==undefined){
      // console.log('data :: ', JSON.stringify(queryState.flattenedData))
      const reportdata = queryState.flattenedData.map((record,index)=>{
        let keys = {}
        keys['key'] = index
        queryState.tableColumnKeys.map((key)=>{
          if(typeof(key)==="string"){
            if(key==='ReferenceNumber'){
              keys[key] = record[key]===undefined?("NOREF0000000"):(record[key])
            }
            else if(key==='RuleTriggered'){
              keys[key] = record[key]===undefined?("No Rule"):(record[key])
            }
            else if(key==='transactionDate'){
              keys[key] = moment(record[key]).utcOffset(0).format('YYYY-MM-DD HH:mm:ss.SS')
            }
            else{
              keys[key] = record[key]===undefined||record[key]===""?("NA"):(record[key])
            }
          }
          if(typeof(key)==="object"){
            Object.values(key)[0].map((innerkey)=>{
              keys[innerkey] = record[innerkey]===undefined||record[innerkey]===""?("NA"):(record[innerkey])
            })
          }
        })
        return keys
      })
      // console.log('DataScource :: ',reportdata)
      return reportdata
    }else{return []}
  }
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
  );

  

  return (
    <div className="no-ele">
      {/* {queryState.reportDropdown?(
        
      ):([])} */}
      <Row style={{ margin:"0.4em 0em 0em 0em", padding:"0.4em 0.8em 0.4em 0.8em" , backgroundColor:"#0c3560"}} >
        <Col span={22}>
          <Space direction="horizontal">
            <DatePicker onChange={e=>
              fromDate(e)}
              showTime={{
                format: 'HH:mm',
              }}
              format={dateFormat}
              size="middle"
              placeholder="Select From Date"
              value={queryState.fromDate?(moment(queryState.fromDate, dateFormat)):('')}
              disabledDate={(current) => {return current>moment() || current>=moment(queryState.toDate,"YYYY-MM-DD").add(1,"day") || current<=moment(queryState.toDate,"YYYY-MM-DD HH:mm").subtract(3,"month").add(331,"minutes") }}
            />
            <DatePicker onChange={e=>
              toDate(e)}
              showTime={{
                format: 'HH:mm',
              }}
              format={dateFormat}
              size="middle"
              placeholder="Select To Date"
              value={queryState.toDate?(moment(queryState.toDate, dateFormat)):('')}
              disabledDate={(current) => {return current>moment() || moment(queryState.fromDate,"YYYY-MM-DD")>current || moment(queryState.fromDate,"YYYY-MM-DD HH:mm").add(3,"month").add(330,"minutes")<=current }}
            />
            <DropDownMenu/>
            {/* <Checkbox onChange={e=>entireReportDetails(e)} checked={queryState.entireDetailsFlag?(queryState.entireDetailsFlag):('')}>Show Entire Report</Checkbox> */}
            <Button type="primary" size="middle" style={{ background: "#ed1c2e", backgroundColor: "#ed1c2e", borderColor: "#ed1c2e" }} onClick={e=>submitReport(e)}>Fetch Kyc Records</Button>
          </Space>
        </Col>
        {/* <Col span={2} >
          <Row justify="end">
            <Button type="primary" size="middle" style={{ background: "#ed1c2e", backgroundColor: "#ed1c2e", borderColor: "#ed1c2e" }} icon={<DownloadOutlined style={{ display:"inline-flex"}}/>} onClick={e=>exportReportData(e)} disabled={!queryState.isReportData||queryState.issubmit}>Export</Button>
          </Row>
        </Col> */}
      </Row>
      <Row>
        <Col span={24} >
        <React.Fragment>
          {queryState.isSpin?(
            <div className="spin-ele">
              <Spin/>
            </div>
          
          ):([])}
          {queryState.isErrorReport && !queryState.isReportData ? (
            <div style={{display:"block" , marginBottom:"0.8em"}}>
              <Alert
                message={queryState.reportError}
                type="error"
                closable
              />
            </div>
          ):(queryState.isReportData ?(
            <div>              
              <div className="spin-ele2">
                <span style={{fontWeight: "bold"}}>Showing {queryState.totalRecordsCount} Records</span>
                {queryState.isLoad?(
                  <Spin indicator={antIcon}/>
                ):([])}
              </div>
              <Table 
                dataSource={queryState.isReportData?(dataSource()):([])}
                columns={queryState.isReportData?(columns()):([])} 
                scroll={{ y: queryState.reportDropdown?('55vh'):("63.3vh") }}
                size="small"
                bordered="true"
                pagination={{ showSizeChanger:"true", defaultPageSize:50 }}
                onChange={onChange}
              />
            </div>
          ):([])
          )}
        </React.Fragment>
        </Col>
      </Row>
    </div>
  )
}
export default Reports
