import React, { useContext, useEffect } from 'react'
import { Row, Col, AutoComplete } from "antd";
import "antd/dist/antd.min.css";
import "bootstrap/dist/css/bootstrap.css";
import { QueryContext } from "../../../context/QueryContext";
import dal from "../../../dal/api-call"

function AddressBox() {
  const { queryState, queryDispatch } = useContext(QueryContext);

  useEffect(async() => {
    if(queryState.testQuerySearchQuery&&queryState.testQuerySearchQuery.length>4&&queryState.testQueryCheck&&!queryState.isFormatApi){
      await getSearchOption()
    }else if(queryState.isFormatApi){
      await getFormatOption()
    }else{
      queryDispatch({ type: "RESET_SEARCH_OPTION"})
    }
  }, [queryState.testQuerySearchQuery,queryState.testQueryCheck,queryState.searchAddressKey,queryState.isFormatApi])

  const getSearchOption = async () => {
    try {
      const response = await dal.request_search(`https://dev-api.ext.cnr.tvx-test.cloud/realtime/v4/cnr/address/autocomplete/search?source=${queryState.testQuerySource}&searchQuery=${queryState.testQuerySearchQuery}&country=${queryState.testQuerycountry}`)
      let response_data = JSON.parse(response.data.body).addresses.result
      let response_option = response_data.map(e=>{ return {"label":e.address,"value":e.address}})
      // console.log("response option :: ",response_option)
      queryDispatch({ type: "UPDATE_SEARCH_OPTION", value:response_data, option:response_option })
    }
    catch{}
  }

  const getFormatOption = async () => {
    let requestOption = {
      headers:{
        "x-wsse-token":"eyJVc2VybmFtZSI6ImFlLXN1cmVjbG91ZC1kdWVkaWwiLCJQYXNzd29yZERpZ2VzdCI6Ik1qSXlZVGt6TVRFelpUYzRNR1U0Wm1WaFlURXlNemxrTXpjME5XSmtPRFEzT1RNeFltUTNZV0ppWVRFM05UaGtOemhtTkdVeFlqZ3hZamcwTUdaak1HWTJOemt6TUdaaFlUVTFaVGcyTm1VMU1EaGtOVEl4Tm1JeU1UTmlNMlkzWWpJek9ESXlNamxpWlRabVpEQmhZVFZrTURsaFkyUmhPV0ZrT1Rkak9EST0iLCJOb25jZSI6ImE3NWNhYjZlZDNkZmY4NGU2N2QwMTdmZWMyMTNmYzQ1IiwiQ3JlYXRlZCI6IjIwMjQtMTEtMjdUMDk6NDQ6MzkuNTQ4WiJ9"
      }
    }
    try {
      const response = await dal.request_search(`https://dev-api.ext.cnr.tvx-test.cloud/realtime/v4/cnr/address/format?addressKey=${queryState.searchAddressKey}`, requestOption=requestOption)
      let response_data = JSON.parse(response.data.body).address
      queryDispatch({ type: "IS_FORTMAT_FETCH", value:response_data?true:false})
      queryDispatch({ type: "SET_FORMAT_ADDRESS_1", value:response_data.addressLine1?response_data.addressLine1:""})
      queryDispatch({ type: "SET_FORMAT_ADDRESS_2", value:response_data.addressLine2?response_data.addressLine2:""})
      queryDispatch({ type: "SET_FORMAT_TOWN_CITY", value:response_data.locality?response_data.locality:""})
      queryDispatch({ type: "SET_FORMAT_COUNTY", value:response_data.county?response_data.county:""})
      queryDispatch({ type: "SET_FORMAT_POST_CODE", value:response_data.postalCode?response_data.postalCode:""})
      queryDispatch({ type: "SET_FORMAT_COUNTRY", value:response_data.country?response_data.country:""})
    }
    catch{}
  }

  const onChange = (e) => {
    queryDispatch({ type: "UPDATE_TEXT_QUERY", value:e })
    queryDispatch({type: "SET_ADDRESS_KEY", value:""})
    queryDispatch({type: "RESET_FORMAT_ADDRESS"})
  }

  const setvalue=(value)=>{
    queryState.searchOptionsObject.map(option=>option.address.toLowerCase().includes(value.toLowerCase())?queryDispatch({type: "SET_ADDRESS_KEY", value:option.addressKey}):([]))
    queryDispatch({ type: "RESET_SEARCH_OPTION"})
  }

  const handleAddress1Change=(value)=>{
    queryDispatch({ type: "SET_FORMAT_ADDRESS_1", value:value})
  }
  const handleAddress2Change=(value)=>{
    queryDispatch({ type: "SET_FORMAT_ADDRESS_2", value:value})
  }
  const handleTownCityChange=(value)=>{
    queryDispatch({ type: "SET_FORMAT_TOWN_CITY", value:value})
  }
  const handleCountyChange=(value)=>{
    queryDispatch({ type: "SET_FORMAT_COUNTY", value:value})
  }
  const handlePostCodeChange=(value)=>{
    queryDispatch({ type: "SET_FORMAT_POST_CODE", value:value})
  }
  const handleCountryChange=(value)=>{
    queryDispatch({ type: "SET_FORMAT_COUNTRY", value:value})
  }

  return (
    <div style={{ minHeight: '78vh', paddingLeft: "12px", backgroundColor: "rgb(249, 251, 255)" }}>
      <Row style={{ borderWidth: "1px", borderStyle: "solid", borderColor: "#c8c8d2", padding: "5px 5px 5px 5px" }}>
        <Col>
          <AutoComplete
            options={queryState.searchOptions}
            style={{ width: "97vw" }}
            value={queryState.testQuerySearchQuery}
            onSelect={e=>setvalue(e)}
            onChange={e=>onChange(e)}
            placeholder="Search Address"
          />
        </Col>
      </Row>
      {queryState.isFormatApi&&queryState.isFormatFetch?(
        <React.Fragment>
          <Row style={{ borderWidth: "1px", borderStyle: "solid", borderColor: "#c8c8d2", padding: "5px 5px 5px 10px", marginTop: "10px" }}>
            {queryState.formatAddress1?(
              <Row style={{ width: "100%"}}>
                <span style={{ width: "8%", fontSize: "14px"}}>Address 1 </span>:
                <span style={{ paddingLeft: "20px", fontSize: "14px" }}>{queryState.formatAddress1}</span>
              </Row>
            ):([])}
            {queryState.formatAddress2?(
              <Row style={{ width: "100%", marginTop: "4px" }}>
                <span style={{ width: "8%", fontSize: "14px" }}>Address 2 </span>:
                <span style={{ paddingLeft: "20px", fontSize: "14px" }}>{queryState.formatAddress2}</span>
              </Row>
            ):([])}
            {queryState.formatTownCity?(
              <Row style={{ width: "100%", marginTop: "4px" }}>
                <span style={{ width: "8%", fontSize: "14px" }}>Town/City </span>:
                <span style={{ paddingLeft: "20px", fontSize: "14px" }}>{queryState.formatTownCity}</span>
              </Row>
            ):([])}
            {queryState.formatCounty?(
              <Row style={{ width: "100%", marginTop: "4px" }}>
                <span style={{ width: "8%", fontSize: "14px" }}>County</span>:
                <span style={{ paddingLeft: "20px", fontSize: "14px" }}>{queryState.formatCounty}</span>
              </Row>
            ):([])}
            {queryState.formatPostCode?(
              <Row style={{ width: "100%", marginTop: "4px" }}>
                <span style={{ width: "8%", fontSize: "14px" }}>Post Code</span>:
                <span style={{ paddingLeft: "20px", fontSize: "14px" }}>{queryState.formatPostCode}</span>
              </Row>
            ):([])}
            {queryState.formatCountry?(
              <Row style={{ width: "100%", marginTop: "4px" }}>
                <span style={{ width: "8%", fontSize: "14px" }}>Country</span>:
                <span style={{ paddingLeft: "20px", fontSize: "14px" }}>{queryState.formatCountry}</span>
              </Row>
            ):([])}
          </Row>
        </React.Fragment>
      ):([])}
    </div>
  )
}

export default AddressBox
